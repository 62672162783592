import { Badge, ChoiceList, DatePicker, LegacyStack, Text, TextField } from "@shopify/polaris";
import { Progress, Status } from "@shopify/polaris/build/ts/src/components/Badge/types";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { ISmartrrFulfillmentStatus, ISmartrrOrderWithCustomerRelationship } from "@smartrr/shared/entities/Order";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { shopifyGidToNumber, viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { getSymbolFromCurrency } from "@smartrr/shared/utils/formatMoney";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import React, { useState } from "react";

import { DatePickerContainer } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { IdContainer } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import {
  CustomerAutocomplete,
  ItemsPopover,
  ProductsAutoComplete,
  getSelectedDatesFromFilter,
  getTimeFromDate,
  getVariantOptions,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

import { OrderHistoryParsedDataType, OrderHistoryTableColumnKeyType } from "../types";
import { getOrderDate, getOrderTotal } from "../utils";

export const OrderStatusBadge = ({
  fulfillmentStatus,
}: {
  fulfillmentStatus: `${ISmartrrFulfillmentStatus}` | ISmartrrFulfillmentStatus;
}) => {
  let progress: Progress = "incomplete";
  let text = "Unfulfilled";
  let status: Status = "new";

  switch (fulfillmentStatus) {
    case "fulfilled": {
      progress = "complete";
      status = "new";
      text = "Fulfilled";
      break;
    }
    case "partial": {
      progress = "partiallyComplete";
      status = "warning";
      text = "Partially fulfilled";
      break;
    }
    case "unshipped": {
      progress = "incomplete";
      status = "attention";
      text = "Unfulfilled";
      break;
    }
    default: {
      progress = "incomplete";
      status = "new";
      text = "Unfulfilled";
    }
  }

  return (
    <Badge progress={progress} status={status}>
      {text}
    </Badge>
  );
};

export const ValueSelectorForOrdersFilter = ({
  field,
  value,
  setValue,
  currency = "USD",
  customerCurrency = "USD",
  purchasables = [],
}: {
  field: string;
  value: string | string[] | undefined;
  setValue: (value: string | string[] | undefined) => void;
  currency?: CurrencyCode;
  customerCurrency?: CurrencyCode;
  purchasables?: IPurchasable[];
}) => {
  const [{ month, year }, setDate] = useState({ month: new Date().getMonth(), year: new Date().getFullYear() });

  const handleMonthChange = (month: number, year: number) => setDate({ month, year });

  switch (field as OrderHistoryTableColumnKeyType) {
    case "orderProcessedDate": {
      return (
        <DatePickerContainer>
          <DatePicker
            allowRange
            multiMonth
            month={month}
            year={year}
            onChange={({ start, end }) => {
              const filter = [getTimeFromDate(start).toString()];
              if (end) {
                filter.push(getTimeFromDate(end).toString());
              }

              setValue(filter);
            }}
            onMonthChange={handleMonthChange}
            selected={getSelectedDatesFromFilter(value)}
          />
        </DatePickerContainer>
      );
    }
    case "items": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <ProductsAutoComplete
            allowMultiple
            selected={(value as string[]) ?? []}
            options={getVariantOptions(purchasables ?? [])}
            textFieldProps={{
              label: Array.isArray(value) ? value.join(";") : value,
              value: Array.isArray(value) ? value.join(";") : value,
            }}
            onChange={value => setValue(value)}
            onSelect={value => setValue(value)}
          />
        </LegacyStack>
      );
    }
    case "emailOrName": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <CustomerAutocomplete
            selected={Array.isArray(value) ? value : value ? [value] : []}
            handleCustomerChange={setValue}
            textFieldProps={{
              placeholder: "Search...",
            }}
          />
        </LegacyStack>
      );
    }
    case "status": {
      return (
        <LegacyStack spacing="extraTight">
          <ChoiceList
            title=""
            titleHidden
            allowMultiple
            selected={Array.isArray(value) ? value : value ? [value] : []}
            choices={[
              { label: "Fulfilled", value: ISmartrrFulfillmentStatus.FULFILLED },
              { label: "Partially fulfilled", value: ISmartrrFulfillmentStatus.PARTIALLY_SHIPPED },
              { label: "Unfulfilled", value: ISmartrrFulfillmentStatus.UNSHIPPED },
            ]}
            onChange={value => setValue(value)}
          />
        </LegacyStack>
      );
    }
    case "estimatedTotalNet": {
      return (
        <LegacyStack spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={getSymbolFromCurrency(currency)}
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }
    case "totalInCustomerCurrency": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={getSymbolFromCurrency(customerCurrency)}
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }

    case "orderId":
    case "smartrrOrderId": {
      return (
        <LegacyStack spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="text"
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              setValue(value);
            }}
          />
        </LegacyStack>
      );
    }
    default: {
      return (
        <LegacyStack vertical spacing="extraTight">
          <Text variant="bodyMd" as="span">
            Select a filter
          </Text>
        </LegacyStack>
      );
    }
  }
};

export const parseOrdersForIndexTable = (
  orders: ISmartrrOrderWithCustomerRelationship[],
  activeOrg?: IOrganization | null
): OrderHistoryParsedDataType[] => {
  return orders.map((order, index) => ({
    id: String(order.id),
    orderId:
      activeOrg?.myShopifyDomain && order.shopifyId ? (
        <IdContainer
          onClick={() =>
            frontEndTabOpen(
              `https://${activeOrg.myShopifyDomain}/admin/orders/${shopifyGidToNumber(order.shopifyId!)}`
            )
          }
        >
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            {order.shopifyOrderLabel}
          </Text>
        </IdContainer>
      ) : (
        <Text variant="bodyMd" as="span" fontWeight="semibold">
          {order.shopifyOrderLabel}
        </Text>
      ),
    orderProcessedDate: getOrderDate(order.orderProcessedDate, activeOrg?.billingTimezone),
    status: <OrderStatusBadge fulfillmentStatus={order.fulfillmentStatus} />,
    estimatedTotalNet: getOrderTotal(
      // shopify seems to have a rounding issue when doing conversions with a
      // 100% discount where the shop income will display a single cent but the
      // actual value of the order is 0.
      order.totalNet === "0" ? 0 : order.shopIncome,
      order.totalRefund,
      order.orderStatus,
      activeOrg?.shopifyStoreData?.currency,
      false
    ),
    totalInCustomerCurrency: getOrderTotal(
      order.totalNet,
      order.totalRefund,
      order.orderStatus,
      order.orderCurrency
    ),
    emailOrName: `${order.custRel?.firstName} ${order.custRel?.lastName}`,
    smartrrOrderId: viewShopifyId(order.shopifyId),
    items: (
      <ItemsPopover
        stLineItems={order.items}
        activatorTitle={pluralize(
          order.items.reduce((acc, prev) => (acc += prev.quantity), 0),
          "item"
        )}
        itemIndex={index}
      />
    ),
  }));
};
